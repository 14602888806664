import { useEffect, useRef, useState } from 'react';
import useAuthStore from '../stores/authStore';

const TawktoChat = () => {
  const { user, userData } = useAuthStore();
  const scriptRef = useRef(null);
  const [visitorData, setVisitorData] = useState({});

  useEffect(() => {
    setVisitorData({
      name: userData?.name || user?.displayName || '',
      email: userData?.email || user?.email || '',
      userId: user?.uid || userData?.uid || '',
      photoUrl: user?.photoURL || userData?.photoURL || '',
    });
  }, [user, userData]);

  useEffect(() => {
    const TAWK_PROPERTY_ID = '67108a3b4304e3196ad3072c';
    const TAWK_WIDGET_ID = '1iacjosv3';
    const TAWK_API_KEY = '2bdc7e6d0f87e911e050669bc13caf3c5cb8a789';

    if (!scriptRef.current) {
      window.Tawk_LoadStart = new Date();

      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.visitor = {
        name: visitorData.name,
        email: visitorData.email,
      };

      if (visitorData.userId) {
        const hash = window.Tawk_API.generateHash(visitorData.email, TAWK_API_KEY);
        window.Tawk_API.visitor.hash = hash;
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://embed.tawk.to/${TAWK_PROPERTY_ID}/${TAWK_WIDGET_ID}`;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');

      script.onload = () => {
        if (window.Tawk_API) {
          window.Tawk_API.onLoad = () => {
            window.Tawk_API.setAttributes(visitorData, (error) => {
              if (error) {
                console.error('Error setting Tawk.to attributes:', error);
              }
            });
          };
        }
      };

      document.head.appendChild(script);
      scriptRef.current = script;
    }

    return () => {
      if (scriptRef.current && scriptRef.current.parentNode) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
      }
      if (window.Tawk_API) {
        delete window.Tawk_API;
      }
      if ('Tawk_LoadStart' in window) {
        delete window.Tawk_LoadStart;
      }
    };
  }, [visitorData]);

  useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.setAttributes) {
      window.Tawk_API.setAttributes(visitorData, (error) => {
        if (error) {
          console.error('Error updating Tawk.to attributes:', error);
        }
      });
    }
  }, [visitorData]);

  return null;
};

export default TawktoChat;
