import Footer from 'components/footers/Footer.js';
import Header from 'components/headers/Header.js';
import { SectionHeading } from 'components/misc/Headings';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import TermsText from 'components/TermsText';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useEffect } from 'react';
import tw from 'twin.macro';
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

export default function TermsOfServicePage({ headingText = 'Termos e Condições' }) {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView('Terms of Service');
  }, []);

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <TermsText />
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}
