import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { faqs as defaultFAQs } from 'config/faq';
import { ReactComponent as ChevronDownIcon } from 'feather-icons/dist/icons/chevron-down.svg';
import { motion } from 'framer-motion';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;

export default function SingleCol({
  id = '',
  subheading = 'Tire Suas Dúvidas',
  heading = (
    <>
      Perguntas <HighlightedText>Frequentes</HighlightedText>
    </>
  ),
  description = 'Encontre respostas para as perguntas mais comuns sobre o Licitou e como podemos ajudar sua empresa a ter sucesso em licitações.',
  faqs = defaultFAQs,
}) {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const { logAnalyticsEvent } = useAnalytics();

  const toggleQuestion = (questionIndex) => {
    const isOpening = activeQuestionIndex !== questionIndex;
    const question = faqs[questionIndex].question;

    logAnalyticsEvent('faq_interaction', {
      action: isOpening ? 'open' : 'close',
      question_index: questionIndex,
      question_text: question,
    });

    if (activeQuestionIndex === questionIndex) {
      setActiveQuestionIndex(null);
    } else {
      setActiveQuestionIndex(questionIndex);
    }
  };

  const handleExpertClick = () => {
    logAnalyticsEvent('expert_contact_click', {
      source: 'faq_section',
    });
  };

  return (
    <Container id={id}>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: 'auto', marginTop: '16px' },
                    collapsed: { opacity: 0, height: 0, marginTop: '0px' },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <CTAContainer>
        <CTAText>Ainda tem dúvidas? Fale com um especialista agora mesmo!</CTAText>
        <PrimaryButton as="a" href="#contato" onClick={handleExpertClick}>
          Falar com um Especialista
        </PrimaryButton>
      </CTAContainer>
    </Container>
  );
}

const HighlightedText = tw.span`text-primary-500`;
const CTAContainer = tw.div`text-center mt-16`;
const CTAText = tw.h3`font-bold text-2xl mb-4`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
