import { useAnalytics } from 'hooks/useAnalytics';
import Landing from 'pages/Landing.js';
import LoginPage from 'pages/Login.js';
import NotFoundPage from 'pages/NotFound.js';
import PlanSelectionPage from 'pages/PlanSelection.js';
import PrivacyPolicyPage from 'pages/PrivacyPolicy.js';
import SignupPage from 'pages/Signup.js';
import TermsOfServicePage from 'pages/TermsOfService.js';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import GlobalStyles from 'styles/GlobalStyles';

function RouteTracker() {
  const { logAnalyticsEvent } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent('route_change', {
      path: location.pathname,
      search: location.search,
    });
  }, [location, logAnalyticsEvent]);

  return null;
}

export default function App() {
  const initializeAuth = useAuthStore((state) => state.initializeAuth);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  return (
    <>
      <GlobalStyles />
      <Router>
        <RouteTracker />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/planos" element={<PlanSelectionPage />} />
          <Route path="/termos-de-servico" element={<TermsOfServicePage />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicyPage />} />
          <Route path="/assine" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}
