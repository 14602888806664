import WhatsAppButton from 'components/buttons/WhatsAppButton';
import FAQ from 'components/faqs/LandingFaqs.js';
import Features from 'components/features/LandingFeatures.js';
import Footer from 'components/footers/LandingFooter.js';
import Contact from 'components/forms/SimpleContactUs.js';
import Hero from 'components/hero/LandingHero.js';
import ComparePaymentPlans from 'components/pricing/ComparePaymentPlans.js';
import TawktoChat from 'components/TawktoChat';
import Testimonial from 'components/testimonials/Testimonials.js';
import { faqs } from 'config/faq';
import { plans } from 'config/plans';
import testimonials from 'config/testimonials';
import { smoothScroll } from 'helpers/smoothScroll';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import styled from 'styled-components';
import tw from 'twin.macro';

const FullHeightContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HighlightedText = tw.span`text-primary-500 font-bold`;
const CTAButton = tw.a`inline-block py-3 px-8 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 transition duration-300 mt-4`;

const Section = styled.section`
  ${tw`py-20 md:py-24`}
`;

const LightSection = styled(Section)`
  ${tw`bg-gray-100`}
`;

const DarkSection = styled(Section)`
  ${tw`bg-primary-900 text-gray-100`}
`;

const PageContainer = styled.div`
  ${tw`overflow-x-hidden`}
  overflow-y: auto;
  height: 100vh;
  position: relative;
`;

export default function Landing() {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView, logAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('Landing Page');
  }, []);

  const handleAnchorClick = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('#')) {
      e.preventDefault();
      const element = document.querySelector(target);
      if (element) {
        window.history.pushState({}, '', target);

        smoothScroll(e, target);
        logAnalyticsEvent('section_navigation', {
          section: target.replace('#', ''),
        });
      }
    }
  };

  useEffect(() => {
    if (location.hash) {
      const timer = setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          smoothScroll(null, location.hash);
          trackButtonClick(location.hash.replace('#', ''));
        }
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [location]);
  const handleChoosePlan = (planId, durationId) => {
    logAnalyticsEvent('plan_selected', {
      plan_id: planId,
      duration_id: durationId,
      user_status: user ? 'logged_in' : 'guest',
    });

    if (user) {
      navigate(`/planos?plan=${planId}&duration=${durationId}`);
    } else {
      navigate('/assine');
    }
  };

  return (
    <PageContainer>
      <FullHeightContainer>
        <Hero
          heading={
            <>
              Impulsione suas Vendas com o <HighlightedText>Licitou</HighlightedText>
            </>
          }
          description="Economize tempo, reduza erros e aumente suas chances de sucesso em licitações públicas com nossa plataforma completa de IA."
          primaryButtonText="Comece Agora - 14 Dias Grátis Sem Cartão"
          primaryButtonUrl="#planos"
          onAnchorClick={handleAnchorClick}
        />
      </FullHeightContainer>
      <LightSection>
        <ComparePaymentPlans id="planos" plans={plans} onChoose={handleChoosePlan} />
      </LightSection>
      <Section>
        <Features
          id="sobre"
          subheading={
            <>
              Por que escolher o <HighlightedText>Licitou</HighlightedText>
            </>
          }
          heading="Recursos Projetados para o Seu Sucesso"
          description="Nossa plataforma oferece tudo que você precisa para dominar o mercado de licitações."
        />
      </Section>
      <DarkSection>
        <Testimonial
          id="depoimentos"
          subheading="Histórias de Sucesso"
          heading={
            <>
              Clientes que <HighlightedText>Transformaram</HighlightedText> seus Negócios
            </>
          }
          testimonials={testimonials}
        />
      </DarkSection>
      <LightSection>
        <FAQ
          id="duvidas"
          subheading="Tire Suas Dúvidas"
          heading={
            <>
              Perguntas <HighlightedText>Frequentes</HighlightedText>
            </>
          }
          faqs={faqs}
        />
      </LightSection>
      <Section>
        <Contact
          id="contato"
          subheading="Estamos Aqui para Ajudar"
          heading={
            <>
              Fale com um <HighlightedText>Especialista</HighlightedText>
            </>
          }
        />
      </Section>
      <CTASection>
        <h2>Pronto para Revolucionar sua Participação em Licitações?</h2>
        <p>
          Junte-se a milhares de empresas que já estão economizando tempo e ganhando mais contratos.
        </p>
        <CTAButton href="#planos">Comece sua Avaliação de 14 Dias Grátis</CTAButton>
      </CTASection>
      <Footer />
      <TawktoChat />
      <WhatsAppButton position="bottom-left" />
    </PageContainer>
  );
}

const CTASection = styled.section`
  ${tw`py-20 bg-gray-100 text-center`}
  h2 {
    ${tw`text-3xl font-bold mb-4`}
  }
  p {
    ${tw`text-xl mb-8`}
  }
`;
