// Subscription status constants
export const SubscriptionStatus = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAUSED: 'paused',
  UNPAID: 'unpaid',
};

const inactiveStatuses = [
  SubscriptionStatus.INACTIVE,
  SubscriptionStatus.INCOMPLETE,
  SubscriptionStatus.INCOMPLETE_EXPIRED,
];

export const isUserActive = (userData) => {
  // If no user data, return false
  if (!userData) return false;

  // Check if user is admin (admins are always active)
  if (userData.role === 'admin') return true;

  // Check basic active flag
  if (userData.hasHadActivePlan) return true;
  if (userData.hasHadTrial) return true;

  // For active subscription status, user is active
  if (userData.subscriptionStatus === SubscriptionStatus.ACTIVE) return true;

  // Check if user has inactive status
  const hasInactiveStatus = inactiveStatuses.includes(userData.subscriptionStatus);
  if (hasInactiveStatus) return false;

  // Special handling for trial users
  if (userData.subscriptionStatus === SubscriptionStatus.TRIALING) {
    return true;
  }

  // User is active if they reach here
  return true;
};
