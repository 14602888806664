import { DialogContent, DialogOverlay } from '@reach/dialog';
import WhatsAppButton from 'components/buttons/WhatsAppButton';
import Footer from 'components/footers/Footer';
import Header from 'components/headers/Header';
import PaymentPlans from 'components/pricing/PaymentPlans';
import TawktoChat from 'components/TawktoChat';
import TermsTextComponent from 'components/TermsText';
import { planDurations, plans } from 'config/plans';
import { smoothScroll } from 'helpers/smoothScroll';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import usePlanStore from 'stores/planStore';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = tw.div`relative`;
const PageContainer = styled.div`
  ${tw`overflow-x-hidden`}
`;
const SuccessMessage = tw.p`text-green-500 text-lg mt-2 text-center font-medium`;
const LoadingMessage = tw.p`text-gray-500 text-lg mt-2 text-center font-medium`;

const TermsModalOverlay = styled(DialogOverlay)`
  ${tw`fixed inset-0 z-50 bg-black bg-opacity-50`}
`;

const TermsModalContent = styled(DialogContent)`
  ${tw`w-full max-w-3xl mx-auto mt-20 bg-white rounded-lg shadow-2xl p-8`}
  width: 80vw;
  height: 80vh;
`;

const ModalTitle = tw.h2`text-2xl font-bold mb-6 text-gray-900`;
const ScrollableContent = styled.div`
  ${tw`overflow-y-auto mb-8`}
  height: calc(80vh - 180px); // Adjust for title and buttons
`;

const ModalFooter = tw.div`flex justify-end gap-4 mt-4`;
const ModalButton = tw.button`px-4 py-2 rounded font-medium`;
const AcceptButton = tw(ModalButton)`bg-primary-500 text-white hover:bg-primary-700`;
const DeclineButton = tw(ModalButton)`bg-gray-200 text-gray-800 hover:bg-gray-300`;

const StyledTermsText = styled.div`
  ${tw`text-sm`}

  h1, h2, h3 {
    ${tw`text-base mb-2`}
  }

  p {
    ${tw`text-sm mb-2`}
  }

  ul,
  ol {
    ${tw`text-sm ml-4`}
  }
`;

const OverlayContainer = tw.div`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`;
const SpinnerContainer = tw.div`bg-white p-8 rounded-lg shadow-xl text-center max-w-lg mx-auto`;
const Spinner = tw.div`animate-spin rounded-full h-10 w-10 border-b-2 border-primary-500 mx-auto mb-4`;
const EnhancedLoadingMessage = tw(LoadingMessage)`text-xl font-semibold`;
const EnhancedSuccessMessage = tw(SuccessMessage)`text-xl font-semibold`;

const ActivePlanBanner = tw.div`bg-primary-100 border-l-4 border-primary-500 p-6 mb-8 mt-4 text-center`;
const ActivePlanTitle = tw.h3`text-primary-900 font-bold text-xl mb-2`;
const ActivePlanText = tw.p`text-primary-800 text-lg`;

const MessageBanner = tw(ActivePlanBanner)`mb-8 mt-4`;
const MessageTitle = tw(ActivePlanTitle)``;
const MessageText = tw(ActivePlanText)``;

export default function PlanSelectionPage() {
  const { user, loading, userData, getToken, getAppRedirectUrl } = useAuthStore();
  const {
    selectedPlan,
    error,
    success,
    processing,
    setSelectedPlan,
    startTrial,
    checkTrialEligibility,
    isTrialEligible,
    setSelectedDuration,
    setError,
    setSuccess,
  } = usePlanStore();

  const navigate = useNavigate();
  const [showTrialExpiredMessage, setShowTrialExpiredMessage] = useState(false);
  const [showTrialIneligibleMessage, setShowTrialIneligibleMessage] = useState(false);
  const { trackPageView, logAnalyticsEvent } = useAnalytics();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [pendingPlan, setPendingPlan] = useState(null);
  const [isCreatingTrial, setIsCreatingTrial] = useState(false);
  const [trialSuccess, setTrialSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState({
    companyName: '',
    field: '',
    phone: '',
  });

  // Get URL parameters and find initial duration index
  const searchParams = new URLSearchParams(window.location.search);
  const planFromUrl = searchParams.get('plan');
  const durationFromUrl = searchParams.get('duration');
  const initialDurationIndex = durationFromUrl
    ? planDurations.findIndex((d) => d.id === durationFromUrl)
    : 0;

  useEffect(() => {
    trackPageView('Plan Selection');
    if (userData?.trialExpired) {
      logAnalyticsEvent('trial_expired_view', {
        user_id: user?.uid,
      });
    }
  }, []);
  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate('/assine');
      } else if (userData?.trialExpired) {
        setShowTrialExpiredMessage(true);
      }
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (user && !loading) {
      checkTrialEligibility().then((isEligible) => {
        if (!isEligible) {
          setShowTrialIneligibleMessage(true);
        }
      });
    }
  }, [user, loading]);

  useEffect(() => {
    if (showTermsModal) {
      setTimeout(() => {
        const scrollableContent = document.getElementById('scrollable-terms-content');
        if (scrollableContent) {
          scrollableContent.scrollTop = 0;
        }
      }, 100);
    }
  }, [showTermsModal]);

  useEffect(() => {
    if (!loading && user) {
      if (planFromUrl) {
        const plan = plans.find((p) => p.id === planFromUrl);
        if (plan) {
          setSelectedPlan(planFromUrl);
          if (durationFromUrl) {
            const duration = planDurations.find((d) => d.id === durationFromUrl);
            if (duration) {
              setSelectedDuration(duration.id);
            }
          }
        }
      }
    }
  }, [loading, user, planFromUrl, durationFromUrl]);

  // Scroll to message if needed
  useEffect(() => {
    if (
      error ||
      success ||
      showTrialExpiredMessage ||
      showTrialIneligibleMessage ||
      userData?.isActive
    ) {
      smoothScroll(null, '#message-container');
    }
  }, [error, success, showTrialExpiredMessage, showTrialIneligibleMessage, userData?.isActive]);

  const handleChoosePlan = async (planId, durationId, userInfo) => {
    if (userData?.isActive) {
      logAnalyticsEvent('plan_selection_blocked', {
        reason: 'active_subscription',
        user_id: user?.uid,
      });
      setError('Você já possui uma assinatura ativa.');
      return;
    }

    if (!isTrialEligible || showTrialIneligibleMessage || showTrialExpiredMessage) {
      logAnalyticsEvent('direct_subscription_redirect', {
        user_id: user?.uid,
        plan_id: planId,
        duration_id: durationId,
        reason: showTrialExpiredMessage ? 'trial_expired' : 'trial_ineligible',
      });

      try {
        const token = await getToken();
        if (token) {
          const url = await getAppRedirectUrl(token, false, '/configuracoes/pagamento');
          window.location.href = url ? `${url}&plan=${planId}&duration=${durationId}` : `/planos`;
        }
      } catch (err) {
        setError('Erro ao redirecionar para página de pagamento. Tente novamente.');
      }
      return;
    }

    setPendingPlan({ planId, durationId });
    setUserInfo(userInfo);
    setShowTermsModal(true);
  };

  const handleAcceptTerms = async () => {
    logAnalyticsEvent('terms_accepted', { user_id: user?.uid });
    setShowTermsModal(false);
    setIsCreatingTrial(true);
    setError(null);
    setSuccess(false);

    if (pendingPlan) {
      const { planId, durationId } = pendingPlan;
      logAnalyticsEvent('trial_start_attempt', {
        plan_id: planId,
        duration_id: durationId,
        user_id: user?.uid,
      });

      setSelectedPlan(planId);
      setSelectedDuration(durationId);

      const success = await startTrial(
        planId,
        durationId,
        userInfo.companyName,
        userInfo.field,
        userInfo.phone,
      );

      if (success) {
        logAnalyticsEvent('trial_start_success', {
          plan_id: planId,
          duration_id: durationId,
          user_id: user?.uid,
        });
        setTrialSuccess(true);
        setSuccess(true); // Set success state for the message container
        // Wait a moment to show the success message before redirecting
        setTimeout(async () => {
          const token = await getToken();
          if (token) {
            const url = await getAppRedirectUrl(token, true);
            window.location.href = url;
          }
        }, 2000);
      } else {
        logAnalyticsEvent('trial_start_error', {
          plan_id: planId,
          duration_id: durationId,
          user_id: user?.uid,
          error: error,
        });
        setIsCreatingTrial(false);
      }
    }
  };

  const handleDeclineTerms = () => {
    logAnalyticsEvent('terms_declined', { user_id: user?.uid });
    setShowTermsModal(false);
    setPendingPlan(null);
    setError(null); // Clear any errors when declining terms
  };

  return (
    <PageContainer>
      <Header />
      <Container>
        <div id="message-container">
          {userData?.isActive && (
            <MessageBanner>
              <MessageTitle>Você já possui uma assinatura ativa</MessageTitle>
              <MessageText>
                Para alterar seu plano, entre em contato com nosso suporte através do chat ou email.
              </MessageText>
            </MessageBanner>
          )}
          {!userData?.isActive && showTrialExpiredMessage && (
            <MessageBanner className="bg-yellow-100 border-yellow-500">
              <MessageTitle className="text-yellow-900">
                Período de Avaliação Encerrado
              </MessageTitle>
              <MessageText className="text-yellow-800">
                Seu período de avaliação gratuita expirou. Para continuar usando o Licitou, você
                precisará escolher um plano e assinar.
              </MessageText>
            </MessageBanner>
          )}
          {!userData?.isActive && showTrialIneligibleMessage && (
            <MessageBanner className="bg-yellow-100 border-yellow-500">
              <MessageTitle className="text-yellow-900">
                Avaliação Gratuita Indisponível
              </MessageTitle>
              <MessageText className="text-yellow-800">
                Você não está elegível para um novo período de avaliação gratuita. Para usar o
                Licitou, você precisará escolher um plano e assinar.
              </MessageText>
            </MessageBanner>
          )}
          {error && (
            <MessageBanner className="bg-red-100 border-red-500">
              <MessageTitle className="text-red-900">Erro</MessageTitle>
              <MessageText className="text-red-800">{error}</MessageText>
            </MessageBanner>
          )}
          {success && (
            <MessageBanner className="bg-green-100 border-green-500">
              <MessageTitle className="text-green-900">Sucesso</MessageTitle>
              <MessageText className="text-green-800">
                Plano ativado com sucesso! Redirecionando...
              </MessageText>
            </MessageBanner>
          )}
        </div>
        <PaymentPlans
          plans={plans}
          planDurations={planDurations}
          primaryButtonText={
            userData?.isActive
              ? 'Outro Plano Já Ativo'
              : showTrialExpiredMessage || showTrialIneligibleMessage || !isTrialEligible
                ? 'Assinar Agora'
                : 'Começar Avaliação Gratuita'
          }
          onChoose={handleChoosePlan}
          selectedPlanId={selectedPlan}
          initialDurationIndex={initialDurationIndex}
          disableButtons={userData?.isActive}
          isTrialEligible={
            isTrialEligible && !showTrialExpiredMessage && !showTrialIneligibleMessage
          }
        />
      </Container>
      <Footer />
      {showTermsModal && (
        <TermsModalOverlay isOpen onDismiss={() => {}}>
          <TermsModalContent aria-label="Terms of Service">
            <ModalTitle>Termos de Serviço</ModalTitle>
            <ScrollableContent id="scrollable-terms-content">
              <StyledTermsText>
                <TermsTextComponent />
              </StyledTermsText>
            </ScrollableContent>
            <ModalFooter>
              <DeclineButton onClick={handleDeclineTerms}>Recusar</DeclineButton>
              <AcceptButton onClick={handleAcceptTerms}>Aceitar e Continuar</AcceptButton>
            </ModalFooter>
          </TermsModalContent>
        </TermsModalOverlay>
      )}
      {(isCreatingTrial || processing) && (
        <OverlayContainer>
          <SpinnerContainer>
            <Spinner />
            {trialSuccess ? (
              <EnhancedSuccessMessage>
                Período de avaliação criado com sucesso! Redirecionando...
              </EnhancedSuccessMessage>
            ) : (
              <EnhancedLoadingMessage>Criando seu período de avaliação...</EnhancedLoadingMessage>
            )}
          </SpinnerContainer>
        </OverlayContainer>
      )}
      <TawktoChat />
      <WhatsAppButton position="bottom-left" />
    </PageContainer>
  );
}
