import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';

import defaultCardImage from 'images/shield-icon.svg';

import { ReactComponent as SvgDecoratorBlob3 } from 'images/svg-decorator-blob-3.svg';

import CustomizeIconImage from 'images/customize-icon.svg';
import FastIconImage from 'images/fast-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import SimpleIconImage from 'images/simple-icon.svg';
import SupportIconImage from 'images/support-icon.svg';

import { useAnalytics } from 'hooks/useAnalytics';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const HighlightedText = tw.span`text-primary-500`;
const CTAContainer = tw.div`text-center mt-16`;
const CTAText = tw.h3`font-bold text-2xl mb-4`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

export default function LandingFeatures({
  cards = null,
  heading = (
    <>
      Recursos <HighlightedText>Projetados</HighlightedText> para o Seu Sucesso
    </>
  ),
  subheading = 'Por que escolher o Licitou',
  description = 'Nossa plataforma oferece tudo que você precisa para dominar o mercado de licitações.',
  id = '',
}) {
  const { logAnalyticsEvent } = useAnalytics();

  const defaultCards = [
    {
      imageSrc: FastIconImage,
      title: 'Busca Inteligente de Licitações',
      description: 'Encontre oportunidades relevantes em segundos com nossa IA avançada.',
    },
    {
      imageSrc: ShieldIconImage,
      title: 'Análise de Editais Automatizada',
      description: 'Economize horas com resumos automáticos e destaques de pontos críticos.',
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'Gerenciamento Completo de Documentos',
      description: 'Mantenha todos os seus documentos organizados e atualizados em um só lugar.',
    },
    {
      imageSrc: SimpleIconImage,
      title: 'Painel de Controle',
      description:
        'Visão geral de todas as licitações em andamento. Acompanhamento de prazos e etapas do processo.',
    },
    {
      imageSrc: ShieldIconImage,
      title: 'Alertas Personalizados',
      description:
        'Notificações por e-mail ou push sobre novas oportunidades. Lembretes de prazos importantes.',
    },
    {
      imageSrc: SupportIconImage,
      title: 'Suporte Especializado',
      description:
        'Chat ao vivo com especialistas em licitações. Base de conhecimento com tutoriais e melhores práticas.',
    },
  ];

  if (!cards) cards = defaultCards;

  const handleFeatureClick = (cardTitle) => {
    logAnalyticsEvent('feature_card_click', {
      feature_name: cardTitle,
    });
  };

  const handleCTAClick = () => {
    logAnalyticsEvent('features_cta_click', {
      location: 'features_section',
    });
  };

  return (
    <Container id={id}>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card onClick={() => handleFeatureClick(card.title)}>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <CTAContainer>
        <CTAText>Pronto para experimentar todos esses recursos?</CTAText>
        <PrimaryButton as="a" href="#planos" onClick={handleCTAClick}>
          Começar Avaliação Gratuita
        </PrimaryButton>
      </CTAContainer>
      <DecoratorBlob />
    </Container>
  );
}
