import { useAnalytics } from 'hooks/useAnalytics';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { smoothScroll } from '../../helpers/smoothScroll';

import { ReactComponent as EmailIcon } from 'feather-icons/dist/icons/mail.svg';
// import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as InstagramIcon } from 'images/instagram-icon.svg';
import LogoImage from 'images/logo-light.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';
import { ReactComponent as YoutubeIcon } from 'images/youtube-icon.svg';

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1,
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1,
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default function LandingFooter() {
  const { logAnalyticsEvent } = useAnalytics();

  const handleSmoothScroll = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('#')) {
      logAnalyticsEvent('footer_navigation', {
        link_target: target.substring(1),
        link_type: 'anchor',
      });
      smoothScroll(e, target);
    }
  };

  const handleExternalLink = (platform) => {
    logAnalyticsEvent('footer_external_link', {
      platform,
      link_type: 'external',
    });
  };

  const handleEmailClick = () => {
    logAnalyticsEvent('footer_contact', {
      method: 'email',
    });
  };

  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>Principal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/#duvidas" onClick={handleSmoothScroll}>
                  FAQ
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://licitou.tawk.help/" target="_blank" rel="noopener noreferrer">
                  Suporte
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/#sobre" onClick={handleSmoothScroll}>
                  Sobre Nós
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:contato@licitou.com.br" onClick={handleEmailClick}>
                  contato@licitou.com.br
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Produto</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://app.licitou.com.br" onClick={() => handleExternalLink('app')}>
                  Entrar
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/#planos" onClick={handleSmoothScroll}>
                  Planos
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/politica-de-privacidade">Política de Privacidade</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/termos-de-servico">Termos de Serviço</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Licitou Ltda.</LogoText>
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2025 Licitou Ltda. Todos os Direitos Reservados.
          </CopywrightNotice>
          <SocialLinksContainer>
            {/* <SocialLink href="https://www.facebook.com/licitou.com.br">
              <FacebookIcon />
            </SocialLink> */}
            <SocialLink
              href="https://www.instagram.com/licitouoficial"
              onClick={() => handleExternalLink('instagram')}
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/@licitou"
              onClick={() => handleExternalLink('youtube')}
            >
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="mailto:contato@licitou.com.br" onClick={handleEmailClick}>
              <EmailIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
}
